import './App.css';
import Login from './Pagine/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Ordine from './Pagine/Ordine';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Registro from './Pagine/Registro';
import Lista from './Pagine/Lista';
import PrivateRoute from './Components/PrivateRoute';
import { itIT } from '@mui/x-data-grid';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#454B50',
    }, secondary: {
      main: '#77625b',
    },
  },
},
itIT,
);

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
    <div className="App">
      <Router>
        <Routes>
          <Route path='*' element={<Login/>}/>
          <Route exact path='/registro' element={<PrivateRoute><Registro/></PrivateRoute>}/>
          <Route exact path='/ordine' element={<PrivateRoute><Ordine/></PrivateRoute>}>
            <Route exact path=':id' element={<PrivateRoute><Ordine/></PrivateRoute>}/>
          </Route>
          {/*<Route path='/lista' element={<PrivateRoute><ListaGrid/></PrivateRoute>}/>*/}
          <Route exact path='/lista' element={<PrivateRoute><Lista/></PrivateRoute>}/>
        </Routes>
      </Router>
    </div>
    </ThemeProvider>
  );
}

export default App;
