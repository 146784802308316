import { Delete } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Switch,
} from "@mui/material";

const RigheOrdine = ({ righe, onDelete, onSetPreparato,idOrdine }) => {

  function renderDeleteButton(id) {
    if (onDelete != undefined) {
      return  <IconButton
      edge="end"
      aria-label="delete"
      onClick={(e) => {
        onDelete(id);
      }}
      key={id}
    >
      <Delete color="error" />
    </IconButton>
    }
  };

  return (
    <>
      {righe.length > 0 && (
        <Box
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.09)",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: 1,
            marginTop:'-15px'
          }}
        >
          <List>
            {righe.map((r) => {
              return (
                <ListItem
                dense={true}
                  disablePadding
                  key={r.id}
                  secondaryAction={ 
                    renderDeleteButton(r.id)                    
                  }
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={r.preparato}
                        size="small"  
                        color="warning"  
                        onChange={(e) => {
                          r.preparato = e.target.checked;
                          onSetPreparato(idOrdine,r.id, r.preparato);
                        }}
                      />
                    }
                    labelPlacement="top"
                    title="Preparato"
                    //label="Preparato"
                  />
                    <ListItemText
                      primary={r.descrizione}
                      secondary={`${r.quantita} ${r.um}`}
                    />                  
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </>
  );
};

export default RigheOrdine;
