import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { APICodes } from "./API";
import { InputAdornment, ListItemButton } from "@mui/material";
import { useLocalStorage } from "../hooks/UseLocalStorage";
import { GridSearchIcon } from "@mui/x-data-grid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RicercaCliente = ({Open,onClose,onSelected}) => {
  
  const [listaClienti, setClienti] = useState({clienti:[]});
  const [searchword, setSearchword] = useState('');
  const [token, setToken] = useLocalStorage("token", undefined);

  const handleSearch = (e) => {
    setSearchword(e.target.value);
    fetchClienti(e.target.value);
  };

  const fetchClienti = (q) => {
    

    const options = {
      method: "GET",
      headers: {
        authorization: "bearer " + token,
      },
    };
    fetch(APICodes.CLIENTI+'?q='+q, options)
      .then((response) => response.json())
      .then((response) => setClienti(response))
      .catch((err) => {console.error(err);
        setClienti({clienti:[]});});
  };

  const doSelected = (id) => {
    onSelected(id);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={Open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Ricerca Utente
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ padding:"6px"}}>
        <TextField fullWidth
        variant="standard"
        color="warning" 
        focused label="Ricerca" id="Ricerca_Utente" value={searchword} onChange={handleSearch}
        InputProps={{
          startAdornment: <InputAdornment position="start"><GridSearchIcon/></InputAdornment>,
        }} />
        </div>
        <List>
        {listaClienti.clienti.map((r) => {
              return (
                <ListItem
                  disablePadding
                  key={r.id}                  
                >
                  <ListItemButton  dense>
                    <ListItemText
                      primary={`${r.nome} ${r.cognome} `}
                      secondary={`${r.telefono} ${r.mail} `}
                      onClick={(e)=>doSelected(r.id)}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Dialog>
    </div>
  );
};

export default RicercaCliente;
