export const STATI_ORDINE = {
  CONSEGNATO: { colore: "#0ec90e", testo: "Consegnato" },
  NONCONSEGNATO: { colore: "orange", testo: "Non Consegnato" },
  NONPREPARATO: { colore: "red", testo: "Non Preparato" },
  PREPARATO: { colore: "yellow", testo: "Preparato" },
};

const ChipStatoOrdine = ({ stato }) => {
  return (
    <span
      style={{
        border: "1px solid gray",
        borderRadius: "20px",
        minWidth: "120px",
        backgroundColor: stato.colore,
        padding: "5px",
        marginBottom: "5px",
        height: "35px",
      }}
    >
      <span
        style={{
          fontWeight: "900",
          color: "black",
          fontSize: "14px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {stato.testo}
      </span>
    </span>
  );
};

export default ChipStatoOrdine;
