import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";

export const ConfermaConsegnaDialog = ({open,OnClose,idOrdine,OnHandleOk}) => {
    //const [open,setOpen] = useState(false);

    const onHandleOk = () =>{
        OnHandleOk(idOrdine);
        OnClose();
    }

    return <Dialog
        open={open}
        onClose={OnClose}
        aria-labelledby="responsive-dialog-title"
    >
        <DialogTitle id="responsive-dialog-title">
            Conferma consegna
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Vuoi impostare l'ordine selezionato come consegnato?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onHandleOk} autoFocus >
                OK
            </Button>
            <Button onClick={OnClose}  autoFocus>
                Annulla
            </Button>
        </DialogActions>
    </Dialog>
}