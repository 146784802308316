const APIPath = process.env.REACT_APP_API_PATH;

const APIVersion = 'v1/ordini/'
export const APICodes = {
    LOGIN: `${process.env.REACT_APP_API_LPATH}`,
    PUNTI_VENDITA: `${APIPath}${APIVersion}puntiven`,
    CLIENTI: `${APIPath}${APIVersion}clienti`,
    ORDINI: `${APIPath}${APIVersion}`,
    ORDINE: `${APIPath}${APIVersion}`,
    ARTICOLI: `${APIPath}${APIVersion}articoli`,
    STATO_RIGA: `${APIPath}${APIVersion}riga/`,
};