import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/UseLocalStorage";
import { Drawer, Hidden, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useState } from "react";

const appVersion = process.env.REACT_APP_VERSION;

export default function ButtonAppBar() {
  const [token, setToken] = useLocalStorage("token", undefined);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = () => {
    setToken("");
    navigate("../");
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "#272727", color: "white" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
          <Typography variant="h5">
            <img
              src="/LogoPasta.png"
              alt="KD_Logo"
              style={{
                height: "70px",
                marginTop: "10px",
              }}
            />
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "white", display: { xs: "none", sm: "block" },marginLeft:'20px', marginTop:'38px', fontSize:'12px' }}
          >
            App Version: {appVersion}
          </Typography>
          </Box>
          <Box>
            <Hidden smDown>
              <Button
                component={Link}
                //to="/ordine"
                onClick={()=>{window.location = '../ordine'; window.location.replace('../ordine');}}
                color="inherit"
                sx={{
                  color: "white",
                  textTransform: "none",
                  fontSize: "18px",
                  marginRight: "15px",
                  fontWeight: "bold",
                }}
              >
                Nuovo Ordine
              </Button>
              <Button
                component={Link}
                to="/registro"
                color="inherit"
                sx={{
                  color: "white",
                  textTransform: "none",
                  fontSize: "18px",
                  marginRight: "15px",
                  fontWeight: "bold",
                }}
              >
                Nuovo Cliente
              </Button>
              <Button
                component={Link}
                to="/lista"
                color="inherit"
                sx={{
                  color: "white",
                  textTransform: "none",
                  fontSize: "18px",
                  marginRight: "15px",
                  fontWeight: "bold",
                }}
              >
                Lista Ordini
              </Button>
              <Button
                onClick={handleClick}
                color="inherit"
                sx={{
                  color: "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Esci
              </Button>
            </Hidden>
            <Hidden smUp>
              <IconButton color="inherit" onClick={toggleDrawer}>
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer}>
        <List>
          <ListItem button component={Link} to="/ordine">
            <ListItemText primary="Nuovo Ordine" />
          </ListItem>
          <ListItem button component={Link} to="/registro">
            <ListItemText primary="Nuovo Clienti" />
          </ListItem>
          <ListItem button component={Link} to="/lista">
            <ListItemText primary="Lista Ordini" />
          </ListItem>
          <ListItem button onClick={handleClick}>
            <ListItemText primary="Esci" />
          </ListItem>
          <ListItem disabled style={{fontSize:'2px'}}>
            <ListItemText primary={`Versione ${appVersion}`}/>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
