import { Navigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/UseLocalStorage";
import { isExpired } from "react-jwt";

const PrivateRoute = ({children}) => {
    const [token, setToken] = useLocalStorage("token", undefined);

    function checkToken(t) {
        if((t === undefined)||(t === null)||(t === '')){
            return false;
        }
        const isMyTokenExpired = isExpired(t);
        if(isMyTokenExpired){
            console.debug("TOKEN expired!");
            //setToken('');
            //return false;
        }
        return true;
    };

    if (!checkToken(token)) {
        // not logged in so redirect to login page with the return url
        return <Navigate to={"../"}/>
}
    return children;
};

export default PrivateRoute
