import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import ResponsiveAppBar from "../Components/NavBar";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { Link, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Search } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/it";
import dayjs from "dayjs";
import { APICodes } from "../Components/API";
import { useLocalStorage } from "../hooks/UseLocalStorage";
import {
  Backdrop,
  Checkbox,
  Chip,
  CircularProgress,
  Fade,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import RicercaCliente from "../Components/RicercaCliente";
import RigheOrdine from "../Components/RigheOrdine";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSessionStorage } from "../hooks/UseSessionStorage";
import Autocomplete from "@mui/material/Autocomplete";

//Recupera il valore massimo del campo id in un array
const maxArrayObjId = (array) => {
  let maxId = 0;
  for (let index = 0; index < array.length; index++) {
    const _id = array[index].id;
    if (_id > maxId) {
      maxId = _id;
    }
  }
  return maxId;
};

//Riga vuota
const emptyRiga = {
  id: "",
  articolo: "",
  descrizione: "",
  um: "",
  quantita: 0,
  prezzo: 0,
  preparato: false,
};

let d = new Date();
//Ordine vuoto
const emptyOrdine = {
  id_ordine: "nuovo",
  cliente: "",
  punto_vendita: "",
  preparato: false,
  consegnato: false,
  annullato: false,
  data_ordine: d,
  data_consegna: d,
  nota: "",
  righe: [],
};

const emptyCliente = { nome: "", cognome: "", telefono: "" };
//Componente pagina ORDINE
const Ordine = () => {
  const [openSearch, setOpenSearch] = React.useState(false);
  const [listaClienti, setClienti] = useSessionStorage("clienti", {
    clienti: [],
  });
  const [listaArticoli, setArticoli] = useState({ articoli: [] });
  const [listaPuntiVendia, setPuntiVendita] = useSessionStorage("pdv", {
    punti_vendita: [],
  });
  const [alertText, setAlertText] = useState();
  const [ordine, setOrdine] = useState(emptyOrdine);
  const [riga, setRiga] = useState(emptyRiga);
  const navigate = useNavigate();
  const params = useParams();
  const [token, setToken] = useLocalStorage("token", undefined);
  const [pdvDefault, setPdvDefault] = useLocalStorage("pdvDefault", "");
  const [loading, setLoading] = useState(false);
  const [nuovoCliene, setNuovoCliene] = useState(emptyCliente);
  const alertBoxRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [backdropVisible, setBackdropVisible] = useState(false);
  const snackbarRef = useRef(null);

  //Recupera lista clienti
  const fetchClienti = (forceFetch = false) => {
    //console.debug(listaClienti);
    if (listaClienti == "" || listaClienti.clienti.length === 0 || forceFetch) {
      const options = {
        method: "GET",
        headers: {
          authorization: "bearer " + token,
        },
      };
      fetch(APICodes.CLIENTI, options)
        .then((response) => response.json())
        .then((response) => setClienti(response))
        .catch((err) => console.error(err));
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  //Recupera lista articoli
  const fetchArticoli = (dt) => {
    function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    }

    function formatDate(date) {
      try {
        return [
          date.getFullYear(),
          padTo2Digits(date.getMonth() + 1),
          padTo2Digits(date.getDate()),
        ].join("-");
      } catch {
        return formatDate(new Date(date));
      }
    }
    const options = {
      method: "GET",
      headers: {
        authorization: "bearer " + token,
      },
    };

    fetch(APICodes.ARTICOLI + "?dt=" + formatDate(dt), options)
      .then((response) => response.json())
      .then((response) => setArticoli(response))
      .catch((err) => console.error(err));
  };

  const fetchOrdine = (_id) => {
    setBackdropVisible(true);
    const options = {
      method: "GET",
      headers: {
        authorization: "bearer " + token,
      },
    };

    fetch(APICodes.ORDINE + _id, options)
      .then((response) => response.json())
      .then((response) => {
        setOrdine(response);
        setBackdropVisible(false);
      })
      .catch((err) => {
        setAlertText(err);
        setBackdropVisible(false);
      });
  };
  function ImpostaPdvDefault() {
    console.log("Imposta pdv default");
    if (pdvDefault != "") {
      setOrdine({ ...ordine, punto_vendita: pdvDefault });
    }
  }

  //Recupera lista PV
  const fetchPuntiVendita = () => {
    if (listaPuntiVendia == "" || listaPuntiVendia.punti_vendita.length === 0) {
      const options = {
        method: "GET",
        headers: {
          authorization: "bearer " + token,
        },
      };

      fetch(APICodes.PUNTI_VENDITA, options)
        .then((response) => response.json())
        .then((response) => {
          setPuntiVendita(response);
          // ImpostaPdvDefault();
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    fetchClienti();
    setNuovoCliene(emptyCliente);
    fetchPuntiVendita();

    if (params.id != undefined && params.id != "" && params.id != "nuovo") {
      fetchOrdine(params.id);
    } else {
      setOrdine(emptyOrdine);
      setRiga(emptyRiga);
    }

    fetchArticoli(ordine.data_consegna);
  }, []);

  useEffect(() => {
    ImpostaPdvDefault();
  }, [listaClienti]);

  //Evento click tasto aggiungi articolo
  const aggiungiRigaClick = () => {
    //Imposta allert vuoto
    setAlertText();
    //controllo che sia stato selezionato un articolo
    if (riga.articolo === "") {
      setAlertText("Selezionare un articolo!");
      return;
    }
    //controllo che la quantità inserita sia valida
    if (
      riga.quantita <= 0 ||
      riga.quantita === "" ||
      riga.quantita === undefined ||
      riga.quantita === null
    ) {
      setAlertText("Quantità non valida!");
      return;
    }

    //Aggiungo la nuova riga all'ordine
    let righe_tmp = ordine.righe;
    riga.id = maxArrayObjId(righe_tmp) + 1;
    righe_tmp.push(riga);

    //Aggiorno le righe dello state ordine
    setOrdine({ ...ordine, righe: righe_tmp });
    setRiga(emptyRiga);
  };

  //Evento invia aordine
  const inviaOrdineClick = () => {
    let ordine_tmp = ordine;

    //Imposta allert vuoto
    setAlertText("");
    //Controlle che il cliente sia stato selezionato
    if (ordine.cliente === "") {
      //setAlertText("Selezionare un cliente!");
      //return;
    }
    //Controlle che il PV sia stato selezionato
    if (ordine.punto_vendita === "") {
      setAlertText("Selezionare il punto vendita di ritiro!");
      return;
    }
    //Controlle che l'ordine abbia almeno una riga da inviare
    if (ordine.righe.length === 0) {
      setAlertText(
        "Prima di inviare l`ordine è necessario aggiungere almeno un articolo!"
      );
      return;
    }

    setLoading(true);

    if (ordine.id_ordine === "nuovo" || ordine.id_ordine === "") {
      if (ordine.cliente == "") {
        if (nuovoCliene.nome === "" && nuovoCliene.cognome === "") {
          setAlertText("Inserire Nome & Cognome");
          setLoading(false);
          return;
        }
        /*if (nuovoCliene.telefono === "") {
          setAlertText("Inserire un recapito telefonico");
          setLoading(false);
          return;
        }*/
        ordine_tmp.nuovo_cliente = nuovoCliene;
        ordine_tmp.cliente = "-1";
        setOrdine({ ...ordine, ordine_tmp });
      }
    }

    const options = {
      method: "POST",
      headers: {
        authorization: "bearer " + token,
      },
      body: JSON.stringify(ordine_tmp),
    };

    fetch(APICodes.ORDINE + ordine.id_ordine, options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response == undefined) {
          setAlertText("Errore invio ordine");
          setLoading(false);
          return;
        } else if (
          response.statuscode !== undefined &&
          response.statuscode != 200
        ) {
          setAlertText(response.message);
          setLoading(false);
          alertBoxRef.current.scrollIntoView();
          return;
        } else {
          reimpostaNuovoOrdine(true);
          setSnackbarMessage("Ordine inviato con successo!");
          setSnackbarOpen(true);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      })
      .catch((err) => {
        console.log("ERROR:" + err);
        setAlertText(
          "Errore di connessione al server, riprovare tra poco o contattare l'assistenza. (" +
            err +
            ")"
        );
        setLoading(false);
      });
  };

  //Evento per rimiovere una riga dall'ordine dato il suo id
  const eliminaRigaOrdine = (_id) => {
    let righe_tmp = ordine.righe;

    for (let i = 0; i < righe_tmp.length; i++) {
      if (righe_tmp[i].id === _id) {
        let spliced = righe_tmp.splice(i, 1);
        console.log("Removed element: " + spliced);
        console.log("Remaining elements: " + righe_tmp);
      }
    }
    setOrdine({ ...ordine, righe: righe_tmp });
  };

  const changeStatoPreparatoRiga = (idOrdine, _id, preparato) => {
    let righe_tmp = ordine.righe;
    for (let i = 0; i < righe_tmp.length; i++) {
      if (righe_tmp[i].id === _id) {
        righe_tmp.preparato = preparato;
        console.log("cambiato stato:" + _id + ">>" + righe_tmp.preparato);
      }
    }
    setOrdine({ ...ordine, righe: righe_tmp });
  };

  function reimpostaNuovoOrdine(forceFetch = false) {
    fetchClienti(forceFetch);
    setLoading(false);
    setOrdine(emptyOrdine);
    setRiga(emptyRiga);
    setNuovoCliene(emptyCliente);
   /* setOrdine({
      ...ordine,
      cliente: "",
      righe: [],
      punto_vendita: pdvDefault,
      nota: "",
    });*/

    //navigate("/ordine");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
   
  }

  //Evento per salvare la Data maxima
  function addMonth(month) {
    let date = new Date();
    date.setMonth(date.getMonth() + month);
    return date;
  }

  function minDateConsegna() {
    let oggi = new Date();
    if (params.id != undefined) {
      let data_cons = new Date(ordine.data_consegna);
      if (data_cons < oggi) {
        return ordine.data_consegna;
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  }

  const handleClickOpen = () => {
    setOpenSearch(true);
  };

  const handleClose = () => {
    setOpenSearch(false);
  };

  const handleSelect = (id) => {
    console.log(id);

    console.log("select2");
    setOrdine({ ...ordine, cliente: id });
    setOpenSearch(false);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropVisible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        ref={snackbarRef}
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="success"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <ResponsiveAppBar />
      <CssBaseline />
      <Container fixed>
        <RicercaCliente
          Open={openSearch}
          onClose={handleClose}
          onSelected={handleSelect}
        />
        <Typography variant="h4" style={{ marginTop: "120px" }}>
          Registrazione dell'ordine
        </Typography>
        <Box mt={5} sx={{ height: "5vh" }}>
          <Grid item xs={12}>
            <Stack spacing={8} direction="row" justifyContent="space-between">
              <Link to={`/lista`}>
                <Button
                  variant="contained"
                  style={{
                    padding: "15px",
                    marginRight: "5px",
                    fontWeight: "900",
                  }}
                >
                  Lista Ordini
                </Button>
              </Link>
              <Stack direction="row" justifyContent="end">
                <Link to={`/Registro`}>
                  <IconButton aria-label="add" size="small">
                    <PersonAddAlt1Icon fontSize="large" />
                  </IconButton>
                </Link>
              </Stack>
            </Stack>
            <Stack>
              {ordine.annullato && (
                <Alert severity="error">ORDINE ANNULLATO</Alert>
              )}
            </Stack>
          </Grid>
        </Box>
        <Box
          component="form"
          mt={5}
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.09)",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: 3,
          }}
        >
          <Grid>
            <Grid>
              <Box
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  justifyContent: "center",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="it"
                >
                  <DatePicker
                    readOnly
                    label="Data Ordine"
                    defaultValue={dayjs(ordine.data_ordine)}
                    value={dayjs(ordine.data_ordine)}
                    visible={false}
                    sx={{ visibility: "hidden", display: "none" }}
                  />

                  <DatePicker
                    label="Data Consegna"
                    minDate={dayjs(minDateConsegna())}
                    maxDate={dayjs(addMonth(2))}
                    value={dayjs(ordine.data_consegna)}
                    defaultValue={dayjs(ordine.data_consegna)}
                    onChange={(newValue) => {
                      setOrdine({ ...ordine, data_consegna: newValue });
                      fetchArticoli(newValue);
                    }}
                  />
                </LocalizationProvider>

                <FormControl
                  variant="filled"
                  style={{ marginLeft: "15px", width: "220px" }}
                >
                  <InputLabel id="PuntoVendita_Label">
                    Punto Vendita di Retiro
                  </InputLabel>
                  <Select
                    id="PuntoVendita_Select"
                    label="Punto Vendita di Retiro"
                    value={ordine.punto_vendita}
                    onChange={(e) => {
                      setOrdine({ ...ordine, punto_vendita: e.target.value });
                      setPdvDefault(e.target.value);
                    }}
                  >
                    {listaPuntiVendia != undefined &&
                      listaPuntiVendia.punti_vendita.map((pv) => {
                        return (
                          <MenuItem
                            key={pv.id}
                            value={pv.id}
                          >{`${pv.descrizione} (${pv.indirizzo})`}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>

              <Paper
                elevation={10}
                style={{ marginTop: "40px", padding: "10px" }}
              >
                <Grid
                  container
                  padding={1}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography style={{ fontWeight: "bold", marginTop: "10px" }}>
                    Nuovo Cliente
                  </Typography>
                  <TextField
                    id="filled-basic"
                    label="Nome"
                    variant="filled"
                    size="small"
                    style={{ width: "100%", marginTop: "10px" }}
                    value={nuovoCliene.nome}
                    onChange={(e) => {
                      setNuovoCliene({ ...nuovoCliene, nome: e.target.value });
                    }}
                  />
                  <TextField
                    id="filled-basic"
                    label="Cognome"
                    variant="filled"
                    size="small"
                    style={{ width: "100%", marginTop: "10px" }}
                    value={nuovoCliene.cognome}
                    onChange={(e) => {
                      setNuovoCliene({
                        ...nuovoCliene,
                        cognome: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    size="small"
                    id="filled-basic"
                    label="Telefono"
                    variant="filled"
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    value={nuovoCliene.telefono}
                    onChange={(e) => {
                      setNuovoCliene({
                        ...nuovoCliene,
                        telefono: e.target.value,
                      });
                    }}
                  />

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Cerca Cliente
                    </Typography>
                    <IconButton
                      color="primary"
                      aria-label="Cerca"
                      onClick={handleClickOpen}
                    >
                      <Search />
                    </IconButton>
                  </Grid>

                  <FormControl variant="filled" sx={{ width: "100%" }}>
                    <InputLabel id="NomeCliente_Label">Nome Cliente</InputLabel>
                    <Select
                      size="small"
                      id="NomeCliente_Select"
                      label="Nome Cliente"
                      value={ordine.cliente}
                      onChange={(e) =>
                        setOrdine({ ...ordine, cliente: e.target.value })
                      }
                    >
                      {listaClienti != undefined &&
                        listaClienti.clienti.map((cliente) => {
                          return (
                            <MenuItem
                              key={cliente.id}
                              value={cliente.id}
                            >{`${cliente.nome} ${cliente.cognome} (${cliente.telefono})`}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              padding: 1,
            }}
          ></Box>

          <Box
            component="div"
            sx={{
              bgcolor: "rgba(255, 255, 255, 0.09)",
              display: "flex",
              flexDirection: "column",
              gap: 3,
              padding: 2,
            }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={listaArticoli.articoli}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Articolo" />
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.descrizione} <sup>({option.um})</sup>
                  {option.disponibile !== 1 && (
                    <Chip
                      icon={<ErrorOutlineIcon />}
                      label="Non disponibile per la data selezionata"
                      variant="outlined"
                      size="small"
                      color="warning"
                    />
                  )}
                </Box>
              )}
              getOptionLabel={(option) => option.descrizione}
              //inputValue={riga.descrizione}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setRiga({
                    ...riga,
                    articolo: newValue.id,
                    descrizione: newValue.descrizione,
                    prezzo: newValue.prezzo,
                    um: newValue.um,
                  });
                } else {
                  setRiga(emptyRiga);
                }
              }}
            />

            {/*<FormControl variant="filled" sx={{ width: "100%" }}>
              <InputLabel id="Articolo_Label">Articolo</InputLabel>
              <Select
                id="Articolo_Select"
                label="Articolo"
                value={riga.articolo}
              >
                {listaArticoli != undefined &&
                  listaArticoli.articoli.map((articolo) => {
                    return (
                      <MenuItem
                        key={articolo.id}
                        value={articolo.id}
                        data-um={articolo.um}
                        onClick={(e) => {
                          setRiga({
                            ...riga,
                            articolo: articolo.id,
                            descrizione: articolo.descrizione,
                            prezzo: articolo.prezzo,
                            um: articolo.um,
                          });
                        }}
                      >
                        {articolo.descrizione} <sup>({articolo.um})</sup>
                        {articolo.disponibile !== 1 && (
                          <Chip
                            icon={<ErrorOutlineIcon />}
                            label="Non disponibile per la data selezionata"
                            variant="outlined"
                            size="small"
                            color="warning"
                          />
                        )}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl> 
                */}

            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Quantità
              </InputLabel>
              <Input
                id="InputQuantita"
                endAdornment={
                  <InputAdornment position="start">{riga.um}</InputAdornment>
                }
                type="number"
                step="0.1"
                value={riga.quantita}
                onChange={(e) =>
                  setRiga({ ...riga, quantita: parseFloat(e.target.value) })
                }
                onFocus={(e) => {
                  if (e.target.value == "0") {
                    setRiga({ ...riga, quantita: "" });
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value == "") {
                    setRiga({ ...riga, quantita: 0 });
                  }
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="standard"
              style={{ visibility: "hidden", display: "none" }}
            >
              <InputLabel htmlFor="standard-adornment-amount">
                Prezzo
              </InputLabel>
              <Input
                id="InputPrezzo"
                startAdornment={
                  <InputAdornment position="start">€</InputAdornment>
                }
                type="number"
                step="0.1"
                value={riga.prezzo}
                onChange={(e) => setRiga({ ...riga, prezzo: e.target.value })}
              />
            </FormControl>
            <FormControlLabel
              style={{ visibility: "hidden", display: "none" }}
              control={
                <Switch
                  checked={riga.preparato}
                  onChange={(e) =>
                    setRiga({ ...riga, preparato: e.target.checked })
                  }
                />
              }
              label="Articolo Preparato"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={aggiungiRigaClick}
              style={{
                fontWeight: "900",
              }}
            >
              Aggiungi
            </Button>
            {alertText && (
              <Alert ref={alertBoxRef} severity="warning">
                {alertText}
              </Alert>
            )}
          </Box>
          {ordine.righe.length > 0 && (
            <RigheOrdine
              righe={ordine.righe}
              onDelete={eliminaRigaOrdine}
              onSetPreparato={changeStatoPreparatoRiga}
            />
          )}
          <Box>
            <h5>Stato Ordine</h5>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="space-around"
              alignItems="center"
            >
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={ordine.annullato}
                    onChange={(e) =>
                      setOrdine({ ...ordine, annullato: e.target.checked })
                    }
                    color="error"
                  />
                }
                label="Annulato"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={ordine.preparato}
                    color="warning"
                    onChange={(e) =>
                      setOrdine({ ...ordine, preparato: e.target.checked })
                    }
                  />
                }
                label="Preparato"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={ordine.consegnato}
                    onChange={(e) =>
                      setOrdine({ ...ordine, consegnato: e.target.checked })
                    }
                  />
                }
                label="Consegnato"
              />
            </Stack>
          </Box>
          <TextField
            id="TextField"
            label="Note / Variazione"
            variant="filled"
            multiline
            rows={3}
            value={ordine.nota}
            onChange={(e) => setOrdine({ ...ordine, nota: e.target.value })}
          />
          <Button
            variant="contained"
            onClick={inviaOrdineClick}
            style={{
              fontWeight: "900",
              marginBottom: "30px",
              backgroundColor: "#3cd115",
            }}
          >
            Invia
          </Button>
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? "100ms" : "0ms",
            }}
            unmountOnExit
          >
            <LinearProgress color="secondary" />
          </Fade>
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontWeight: "900",
              visibility: "hidden",
              display: "none",
            }}
            onClick={() => {
              reimpostaNuovoOrdine();
            }}
          >
            Nuovo
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default Ordine;
