import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import { useLocalStorage } from "../hooks/UseLocalStorage";
import { APICodes } from "../Components/API";
import ButtonAppBar from "../Components/NavBar";
import { Autocomplete, Backdrop, CardActions, Chip, CircularProgress, Fab, FormControlLabel, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import RigheOrdine from "../Components/RigheOrdine";
import { ClearIcon, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { formatDate } from "../utils";
import ChipStatoOrdine, { STATI_ORDINE } from "../Components/ChipStatoOrdine";
import { ConfermaConsegnaDialog } from "../Components/ConfermaConsegnaDialog";
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { RefreshOutlined } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';

//Riga vuota
const emptyRiga = {
  id: "",
  articolo: "",
  descrizione: "",
  um: "",
  quantita: 0,
  prezzo: 0,
  preparato: false,
};

const Lista = () => {
  const navigate = useNavigate();
  const [listaPuntiVendita, setPuntiVendita] = useState();
  const [listaOrdini, setListaOrdini] = useState({ ordini: [] });
  const [token, setToken] = useLocalStorage("token", undefined);
  const [punto_vendita, setPuntoVendita] = useLocalStorage("filtro_pdv","");
  const [ricerca, setRicerca] = useLocalStorage("filtro_ricerca","");
  const [riga, setRiga] = useState(emptyRiga);
  const [includiConsegnati, setIncludiConsegnati] = useLocalStorage("filtro_includi_consegnati",false);
  const [includiPreparati, setIncludiPreparati] = useLocalStorage("filtro_includi_preparati",true);
  const [visualizzaInformazione, setVisualizzaInformazione] = useState(false);
  const [filtraConNote, setFiltraConNote] = useLocalStorage("filtro_con_note",false);
  const [includiPeriodo, setIncludiPeriodo] = useLocalStorage("filtro_periodo",false);
  /*const [filtroData, setFiltroData] = useState({
    dal: dayjs(new Date()),
    al: dayjs(new Date()),
  });*/
  const [filtroData, setFiltroData] = useLocalStorage('filtro_date',{
    dal: new Date(),
    al: new Date()
  });
  const [totalData, setTotalData] = useState([]);
  const [conteggioOrdiniConNote, setConteggioOrdiniConNote] = useState([]);
  const [idOrdineConsegna, setIdOrdineConsegna] = useState("");
  const [listaArticoli, setArticoli] = useState({ articoli: [] });
  const [consegnaOrdineDialogOpen, setConsegnaOrdineDialogOpen] =
    useState(false);

  const [backdropVisible, setBackdropVisible] = useState(false);
  const fetchPuntiVendita = () => {
    const options = {
      method: "GET",
      headers: {
        authorization: "bearer " + token,
      },
    };

    fetch(APICodes.PUNTI_VENDITA, options)
      .then((response) => response.json())
      .then((response) => setPuntiVendita(response))
      .catch((err) => {
        console.error(err);
        setPuntiVendita({ punti_vendita: [] });
      });
  };

  const fetchRecuperaOrdini = (pv, dtf, dtt) => {
    
    setBackdropVisible(true);
    
    const options = {
      method: "GET",
      headers: {
        authorization: "bearer " + token,
      },
    };
    let queryString = "";

    if (pv != undefined && pv != "") {
      queryString = `${queryString}&id=${pv}`;
    }
    if (dtf != undefined && dtf != "") {
      queryString = `${queryString}&dtf=${formatDate(new Date(dtf))}`;
    }
    if (includiPeriodo) {
      if (dtt != undefined && dtt != "") {
        queryString = `${queryString}&dtt=${formatDate(new Date(dtt))}`;
      }
    }

    const apiUrl = `${APICodes.ORDINI}?dettaglio=true${queryString}`;

    fetch(apiUrl, options)
      .then((response) => response.json())
      .then((response) => {
        setListaOrdini(response);
        setBackdropVisible(false);
      })
      .catch((err) => {
        console.error(err);
        setBackdropVisible(false);
        setListaOrdini({ ordini: [] });
      });
  };

  //Recupera lista articoli
  const fetchArticoli = (dt) => {
    const options = {
      method: "GET",
      headers: {
        authorization: "bearer " + token,
      },
    };

    fetch(APICodes.ARTICOLI, options)
      .then((response) => response.json())
      .then((response) => setArticoli(response))
      .catch((err) => console.error(err));
  };

  const FiltroListaOrdini = (item) => {
    const search = ricerca.toLowerCase();
    const nome = `${item.nome} ${item.cognome}`.toLowerCase();
    const telefono = item.telefono ? item.telefono.toLowerCase() : "";
    if (!includiPreparati && item.preparato) {
      return false;
    }
    if (!includiConsegnati && item.consegnato) {
      return false;
    }
    if (ricerca !== "") {
      if (nome.includes(search) || telefono.includes(search)) {
        return true;
      } else {
        return false;
      }
    }
    if (filtraConNote) {
      return (item.nota.trim() !== '')
    }
    console.log(riga);
    if ((riga.articolo!==undefined)&&(riga.articolo!=='')){
      for (let index = 0; index < item.righe.length; index++) {
        const rigaOrdine = item.righe[index];
        console.log(rigaOrdine);
        if(rigaOrdine.articolo==riga.articolo){
          return true
        }        
      }
      return false
    }

    return true;
  };

  const changeStatoPreparatoRiga = (idOrdine, _id, preparato) => {
    const data = {
      preparato: preparato,
    };

    const aggiornaStato = () => {
      let ordini_tmp = listaOrdini.ordini;
      for (let index = 0; index < ordini_tmp.length; index++) {
        const ordine = ordini_tmp[index];
        let contNonPreparati = 0;
        let changed = false;
        for (let i = 0; i < ordine.righe.length; i++) {
          const riga = ordine.righe[i];
          if (riga.id === _id && ordine.id == idOrdine) {
            riga.preparato = preparato;
            changed = true;
            console.info("cambiato stato:" + _id + ">>" + riga.preparato);
          }
          if (!riga.preparato) {
            contNonPreparati = contNonPreparati + 1;
          }
        }
        if (changed) {
          //if (contNonPreparati === 0) {
          //console.debug("changed");
          ordine.preparato = contNonPreparati === 0;
          //}
        }
        //console.debug("changed");
      }

      setListaOrdini({ ...listaOrdini, ordini_tmp });
    };

    const options = {
      method: "POST",
      headers: {
        authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    fetch(APICodes.STATO_RIGA + `${idOrdine}/${_id}/stato`, options)
      .then((response) => response.json())
      .then((response) => () => {
        //aggiornaStato();
      })
      .catch((err) => console.error(err));

    aggiornaStato();
  };

  useEffect(() => {
    fetchPuntiVendita();
    fetchArticoli();
    fetchRecuperaOrdini(punto_vendita, filtroData.dal, filtroData.al);
  }, [punto_vendita, ricerca, includiPreparati, includiConsegnati, filtroData,includiPeriodo,riga]);

  useEffect(() => {
    totali();
  }, [listaOrdini]);

  const totali = () => {
    let tot = [];
    let contOrdNote = {
      totale: 0,
      preparati: 0,
      nonPreparati: 0,
      consegnati: 0,
      nonConsegnati: 0,
    };;


    let listaOrdiniFiltrata = listaOrdini.ordini.filter((i) =>
      FiltroListaOrdini(i)
    );

    for (let index = 0; index < listaOrdiniFiltrata.length; index++) {
      const ordine = listaOrdiniFiltrata[index];

      for (let index = 0; index < ordine.righe.length; index++) {
        const riga = ordine.righe[index];

        if (!tot[riga.descrizione]) {
          tot[riga.descrizione] = {
            totale: { um: riga.um, quantita: 0, nordini: 0, conNote: 0 },
            preparati: { um: riga.um, quantita: 0, nordini: 0, conNote: 0 },
            nonPreparati: { um: riga.um, quantita: 0, nordini: 0, conNote: 0 },
            consegnati: { um: riga.um, quantita: 0, nordini: 0, conNote: 0 },
            nonConsegnati: { um: riga.um, quantita: 0, nordini: 0, conNote: 0 },
          };
        }

        tot[riga.descrizione].totale.quantita += riga.quantita;
        tot[riga.descrizione].totale.nordini += 1;

        //preparati
        if (riga.preparato) {
          tot[riga.descrizione].preparati.quantita += riga.quantita;
          tot[riga.descrizione].preparati.nordini += 1;
        } else {
          tot[riga.descrizione].nonPreparati.quantita += riga.quantita;
          tot[riga.descrizione].nonPreparati.nordini += 1;
        }
        //consegnati
        if (ordine.consegnato) {
          tot[riga.descrizione].consegnati.quantita += riga.quantita;
          tot[riga.descrizione].consegnati.nordini += 1;
        } else {
          tot[riga.descrizione].nonConsegnati.quantita += riga.quantita;
          tot[riga.descrizione].nonConsegnati.nordini += 1;
        }
      }

      //Ordini con note 
      if (ordine.preparato) {
        if (ordine.nota.trim() != '') {
          contOrdNote.preparati += 1;
        }
      } else {
        if (ordine.nota.trim() != '') {
          contOrdNote.nonPreparati += 1;
        }
      }
      if (ordine.consegnato) {
        if (ordine.nota.trim() != '') {
          contOrdNote.consegnati += 1;
        }
      } else {
        if (ordine.nota.trim() != '') {
          contOrdNote.nonConsegnati += 1;
        }
      }
      if (ordine.nota.trim() != '') {
        contOrdNote.totale += 1;
      }
    }
    // console.log({totali:tot,conNote:ordiniConNote});
    setTotalData(tot);
    setConteggioOrdiniConNote(contOrdNote)
  };

  const evaluateStatoOrdine = (i) => {
    if (i.preparato) {
      if (i.consegnato) {
        return { colore: "#0ec90e", testo: "Consegnato" };
      } else {
        return STATI_ORDINE.PREPARATO;
      }
    } else {
      return STATI_ORDINE.NONPREPARATO;
    }
  };

  const showDialogConsegna = (id) => {
    setIdOrdineConsegna(id);
    setConsegnaOrdineDialogOpen(true);
    console.debug("consegnato");
  };

  const OKDialogConsegna = () => {
    const aggiornaStatoConsegnato = () => {
      let ordini_tmp = listaOrdini.ordini;
      for (let index = 0; index < ordini_tmp.length; index++) {
        console.debug("> " + ordini_tmp[index].id);
        if (ordini_tmp[index].id === idOrdineConsegna) {
          ordini_tmp[index].consegnato = true;
          console.debug("consegnato " + idOrdineConsegna);
        }
      }

      setListaOrdini({ ...listaOrdini, ordini_tmp });
    };

    const options = {
      method: "POST",
      headers: {
        authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ consegnato: true }),
    };
    fetch(APICodes.ORDINE + `${idOrdineConsegna}/stato`, options)
      .then((response) => response.json())
      .then((response) => () => {
        console.debug(response);
        //dopo POST modifica stato consegnato ordine aggiornare lo stato del componente
        //aggiornaStatoConsegnato();
      })
      .catch((err) => console.error(err));

    aggiornaStatoConsegnato();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  const refresh = () => {
    fetchRecuperaOrdini(punto_vendita, filtroData.dal, filtroData.al);
  }

  return (
    <>
      <ButtonAppBar />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropVisible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container fixed style={{ marginBottom: "100px", }}>
        <ConfermaConsegnaDialog
          OnHandleOk={OKDialogConsegna}
          open={consegnaOrdineDialogOpen}
          idOrdine={idOrdineConsegna}
          OnClose={() => {
            setConsegnaOrdineDialogOpen(false);
          }}
        />
        <Paper >
          <Typography
            variant="h4"
            style={{ marginTop: "130px" }}
          >
            Lista Ordini
          </Typography>

          <Box mt={2}>
            <Stack
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 2 }}
              useFlexGap
              flexWrap="wrap"
              justifyContent="center"
            >
              <Link to={`/ordine`}></Link>
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="Label_PuntoVendita">Punto Vendita</InputLabel>
                <Select
                  onChange={(e) => {
                    setPuntoVendita(e.target.value);
                  }}
                  value={punto_vendita}
                  endAdornment={<IconButton sx={{ display: punto_vendita !== '' ? "" : "none" }} onClick={() => { console.debug('X'); setPuntoVendita(''); }} ><ClearIcon /></IconButton>}
                >
                  {listaPuntiVendita !== undefined &&
                    listaPuntiVendita.punti_vendita.map((pv) => (
                      <MenuItem key={pv.id} value={pv.id}>
                        {`${pv.descrizione} (${pv.indirizzo})`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                label="Ricerca"
                value={ricerca}
                onChange={(e) => setRicerca(e.target.value)}
                sx={{ minWidth: 150 }}
              />

              {/* AutoComplete Ricerca */}

              <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={listaArticoli.articoli}

              renderInput={(params) => (
                <TextField {...params} label="Articolo" style={{Maxwidth:'200px', minWidth: 150}}/>
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.descrizione} <sup>({option.um})</sup>
                </Box>
              )}
              getOptionLabel={(option) => option.descrizione}
              //inputValue={riga.descrizione}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setRiga({
                    ...riga,
                    articolo: newValue.id,
                    descrizione: newValue.descrizione,
                    prezzo: newValue.prezzo,
                    um: newValue.um,
                  });
                } else {
                  setRiga(emptyRiga);
                }
              }}
            />







              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                <DatePicker
                  label="Dal"
                  value={dayjs(filtroData.dal)}
                  //maxDate={filtroData.al}
                  onChange={(newValue) => {
                    setFiltroData({ ...filtroData, dal: newValue });                    
                  }}
                />
                {includiPeriodo && (
                  <DatePicker
                    label="Al"
                    value={dayjs(filtroData.al)}
                    minDate={dayjs(filtroData.dal)}
                    onChange={(newValue) => {
                      setFiltroData({ ...filtroData, al: newValue });
                    }}
                  />
                )}
              </LocalizationProvider>

              <FormControlLabel
                control={<Checkbox />}
                checked={includiPeriodo}
                onChange={(e) => setIncludiPeriodo(e.target.checked)}
                label="Ricerca Periodo"
              />

              <FormControlLabel
                control={<Checkbox />}
                checked={includiPreparati}
                onChange={(e) => setIncludiPreparati(e.target.checked)}
                label="Includi Preparati"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={includiConsegnati}
                onChange={(e) => setIncludiConsegnati(e.target.checked)}
                label="Includi Consegnati"
              />
              <FormControlLabel
                control={<Checkbox checked={visualizzaInformazione} onChange={(e) => setVisualizzaInformazione(e.target.checked)} />}

                label="Info"
              />
              <FormControlLabel
                control={<Checkbox checked={filtraConNote} onChange={(e) => setFiltraConNote(e.target.checked)} />}

                label="Filtra ordini con note"
              />
            </Stack>
          </Box>
        </Paper>


        <Box mt={5}>
          {listaOrdini &&
            listaOrdini.ordini
              .filter((i) => FiltroListaOrdini(i))
              .map((item) => {
                const dataOrdine = new Date(item.data_ordine);
                const dataConsegna = new Date(item.data_consegna);
                return (
                  <Card
                    key={item.id}
                    sx={{ margin: 1 }}
                    style={{ backgroundColor: "#f0eeee" }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        display: "flex",
                        marginLeft: "10px",
                        padding: "7px",
                      }}
                    >
                      Consegna: {item.data_consegna}
                    </span>
                    <CardContent sx={{ textAlign: "left" }}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        style={{ marginTop: "-20px" }}
                      >
                        <Typography variant="h5" component="div">
                          {item.nome} {item.cognome}
                        </Typography>
                        <ChipStatoOrdine stato={evaluateStatoOrdine(item)} />
                      </Stack>

                      <Grid container spacing={2}>
                        <Grid item>
                          <RigheOrdine
                            righe={item.righe}
                            idOrdine={item.id}
                            onSetPreparato={changeStatoPreparatoRiga}
                          />
                        </Grid>
                        {visualizzaInformazione === true && (
                          <Grid item>
                            <Paper elevation={10} style={{ padding: "5px" }}>
                              <Typography variant="body2">
                                Data Ordine: {dataOrdine.toLocaleDateString()}
                              </Typography>
                              <Typography variant="body2">
                                Data Consegna:{" "}
                                {dataConsegna.toLocaleDateString()}
                              </Typography>
                              <Typography variant="body2">
                                Punto Vendita: {item.punto_vendita}
                              </Typography>
                              <Typography variant="body2">
                                Telefone: {item.telefono}
                              </Typography>
                            </Paper>
                          </Grid>)}
                        <Grid item>
                          {(item.nota.trim().length > 0) && (
                            <div elevation={1} style={{ padding: "8px 5px",backgroundColor:'#ff9999',borderRadius:'15px' }}>
                              <Typography variant="body2">
                                <span style={{ fontWeight: "900" }}>Nota:</span>{" "}
                                {item.nota}
                              </Typography>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Grid container>
                      <CardActions
                        style={{ marginTop: "-22px", marginLeft: "15px" }}
                      >
                        <FormControlLabel
                          label="Consegnato"
                          control={
                            <Checkbox
                              size="small"
                              checked={item.consegnato}
                              onClick={() => {
                                if (!item.consegnato) {
                                  showDialogConsegna(item.id);
                                }
                              }}
                              disabled={item.consegnato === true ? true : false}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Preparato"
                          control={
                            <Checkbox
                              size="small"
                              checked={item.preparato}
                              disabled
                            />
                          }
                        />
                        <Button
                          style={{ width: "100%" }}
                          variant="edit"
                          onClick={() => navigate(`/ordine/${item.id}`)}
                        >
                          <EditIcon />
                        </Button>
                      </CardActions>
                    </Grid>
                  </Card>
                );
              })}
          <Card
            sx={{
              //borderRadius: 2,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent style={{ backgroundColor: "#f0eeee" }}>
              <Paper
                variant="outlined"
                style={{
                  padding: "10px",
                  width: "100%",
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
                sx={{ borderColor: STATI_ORDINE.NONPREPARATO.colore }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  style={{
                    marginBottom: "20px",
                    fontWeight: "900",
                    color: "black",
                    backgroundColor: STATI_ORDINE.NONPREPARATO.colore,
                  }}
                >
                  Da Preparare
                </Typography>
                {Object.keys(totalData).map((description) => (
                  <div key={description}>
                    <Typography
                      variant="body1"
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      {description}:
                      <span style={{ marginLeft: "auto" }}>
                        <b>
                          {totalData[description].nonPreparati.quantita}{" "}
                          {totalData[description].nonPreparati.um}
                        </b>
                        <Chip
                          label={totalData[description].nonPreparati.nordini}
                          size="small"
                          color="success"
                          variant="outlined"
                          style={{ marginLeft: "5px" }}
                        />
                      </span>
                    </Typography>
                  </div>
                ))}

                <div>Ordini con note: {conteggioOrdiniConNote.nonPreparati}</div>
              </Paper>

              <Paper
                variant="outlined"
                style={{
                  padding: "10px",
                  display: "flex",
                  marginBottom: "5px",
                  width: "100%",
                  flexDirection: "column",
                }}
                sx={{ borderColor: STATI_ORDINE.PREPARATO.colore }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  style={{
                    marginBottom: "20px",
                    fontWeight: "900",
                    color: "black",
                    backgroundColor: STATI_ORDINE.PREPARATO.colore,
                  }}
                >
                  Preparati
                </Typography>
                {Object.keys(totalData).map((description) => (
                  <div key={description}>
                    <Typography
                      variant="body1"
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      {description}:
                      <span style={{ marginLeft: "auto" }}>
                        <b>
                          {totalData[description].preparati.quantita}{" "}
                          {totalData[description].preparati.um}
                        </b>
                        <Chip
                          label={totalData[description].preparati.nordini}
                          size="small"
                          color="success"
                          variant="outlined"
                          style={{ marginLeft: "5px" }}
                        />
                      </span>
                    </Typography>
                  </div>
                ))}
                <div>Ordini con note: {conteggioOrdiniConNote.preparati}</div>
              </Paper>

              <Paper
                variant="outlined"
                style={{ padding: "10px", width: "100%" }}
                sx={{ borderColor: STATI_ORDINE.CONSEGNATO.colore }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  style={{
                    marginBottom: "20px",
                    fontWeight: "900",
                    color: "black",
                    backgroundColor: STATI_ORDINE.CONSEGNATO.colore,
                  }}
                >
                  {STATI_ORDINE.CONSEGNATO.testo}
                </Typography>
                {Object.keys(totalData).map((description) => (
                  <div key={description} style={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      {description}:
                      <span style={{ marginLeft: "auto" }}>
                        <b>
                          {totalData[description].consegnati.quantita}{" "}
                          {totalData[description].consegnati.um}
                        </b>
                        <Chip
                          label={totalData[description].consegnati.nordini}
                          size="small"
                          color="success"
                          variant="outlined"
                          style={{ marginLeft: "5px" }}
                        />
                      </span>
                    </Typography>
                  </div>
                ))}
                <div>Ordini con note: {conteggioOrdiniConNote.consegnati}</div>
              </Paper>
              <Paper
                variant="outlined"
                style={{
                  padding: "10px",
                  width: "100%",
                  marginTop: "5px",
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid black",
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  style={{ marginBottom: "20px", fontWeight: "900" }}
                >
                  Totali
                </Typography>
                {Object.keys(totalData).map((description) => (
                  <div key={description}>
                    <Typography
                      variant="body1"
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      {description}:
                      <span style={{ marginLeft: "auto" }}>
                        <b>
                          {totalData[description].totale.quantita}{" "}
                          {totalData[description].totale.um}
                        </b>
                        <Chip
                          label={totalData[description].totale.nordini}
                          size="small"
                          color="success"
                          variant="outlined"
                          style={{ marginLeft: "5px" }}
                        />
                        <Chip
                          label={totalData[description].totale.conNote}
                          size="small"
                          color="success"
                          variant="outlined"
                          style={{ marginLeft: "5px" }}
                        />
                      </span>
                    </Typography>
                  </div>
                ))}
                <div>Ordini con note: {conteggioOrdiniConNote.totale}</div>
              </Paper>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', marginBottom: '20px' }}>
        <Fab aria-label="scroll-to-top" onClick={scrollToTop}>
          <NorthIcon />
        </Fab>
        <Fab aria-label="refresh" onClick={refresh} sx={{ ml: 2 }} color="success">
          <RefreshOutlined />
        </Fab>
      </div>
      <div style={{ position: 'fixed', bottom: '20px', left: '20px', marginBottom: '20px' }}>
        <Fab aria-label="scroll-to-top" onClick={scrollToBottom}>
          <SouthIcon />
        </Fab>

      </div>
    </>
  );
};

export default Lista;
