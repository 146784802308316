import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import ResponsiveAppBar from "../Components/NavBar";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import { APICodes } from "../Components/API";
import { useLocalStorage } from "../hooks/UseLocalStorage";
import { Fade, LinearProgress, Typography } from "@mui/material";

const Registro = () => {
  const [formData, setFormData] = useState({
    nome: "",
    cognome: "",
    telefono: "",
    indirizzo: "",
    citta: "",
    provincia: "",
    email: "",
    nota: "",
    privacy: false,
  });

  const [alertText, setAlertText] = useState("");
  const [token, setToken] = useLocalStorage("token", undefined);
  const [loading, setLoading] = useState(false);

  const handleClearForm = () => {
    setFormData({
      nome: "",
      cognome: "",
      telefono: "",
      indirizzo: "",
      citta: "",
      provincia: "",
      email: "",
      nota: "",
      privacy: false,
    });
  };

  function handleClickInvia() {
    setLoading(true);
    setAlertText("");
    if (formData.nome === "" && formData.cognome === "") {
      setAlertText("Inserire Nome & Cognome");
      setLoading(false);
      return;
    };

    if (formData.privacy === false) {
      setAlertText("Devi accettare la privacy");
      setLoading(false);
      return;
    };

    const options = {
      method: "POST",
      headers: {
        authorization: "bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch(APICodes.CLIENTI, options)
      .then((response) => response.json())
      .then((response) => {
        if (response == undefined) {
          setAlertText("Errore Registro Cliente");
          setLoading(false);
        } else if ((response.statuscode !== undefined) &&(response.statuscode != 200)) {
          setAlertText(response.message);
          setLoading(false);
        } else {
          setFormData({
            nome: "",
            cognome: "",
            telefono: "",
            indirizzo: "",
            citta: "",
            provincia: "",
            email: "",
            nota: "",
            privacy: false,
          }); 
          setLoading(false);
        }; 
      }).catch((err) => setAlertText("err.text()"));
  };

  return (
    <>
      <ResponsiveAppBar />
      <CssBaseline />
      <Container fixed>
      <Typography variant="h4" style={{marginTop:'130px'}}>Registrazione del Cliente</Typography>
        <Box mt={5} sx={{ height: "5vh" }}>
          <Grid item xs={12}>
            <Stack spacing={8} direction="row" justifyContent="start">
              <Link to={`/Ordine`}>
                <Button variant="contained" style={{
                padding: "15px",
                marginRight: "5px",
                fontWeight: '900',
                
              }}>Registro Ordine</Button>
              </Link>
            </Stack>
          </Grid>
        </Box>
        <Box
          component="form"
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.09)",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: 3,
            marginTop: '20px'
          }}
        >
          <TextField
            id="nome"
            label="Nome"
            variant="filled"
            value={formData.nome}
            onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
          />
          <TextField
            id="cognome"
            label="Cognome"
            variant="filled"
            value={formData.cognome}
            onChange={(e) =>
              setFormData({ ...formData, cognome: e.target.value })
            }
          />
          <TextField
            id="contattoTelefonico"
            label="Contatto Telefonico"
            variant="filled"
            value={formData.telefono}
            onChange={(e) =>
              setFormData({ ...formData, telefono: e.target.value })
            }
          />
          <TextField
            id="indirizzo"
            label="Indirizzo"
            variant="filled"
            value={formData.indirizzo}
            onChange={(e) =>
              setFormData({ ...formData, indirizzo: e.target.value })
            }
          />
          <TextField
            id="citta"
            label="Città"
            variant="filled"
            value={formData.citta}
            onChange={(e) =>
              setFormData({ ...formData, citta: e.target.value })
            }
          />
          <FormControl variant="filled">
            <InputLabel id="InputProvincia">Provincia</InputLabel>
            <Select
              id="Select_provincia"
              label="Provincia"
              onChange={(e) =>
                setFormData({ ...formData, provincia: e.target.value })
              }
            >
              <MenuItem value="TR">Terni</MenuItem>
              <MenuItem value="AG">Agrigento</MenuItem>
              <MenuItem value="AL">Alessandria</MenuItem>
              <MenuItem value="AN">Ancona</MenuItem>
              <MenuItem value="AO">Aosta</MenuItem>
              <MenuItem value="AR">Arezzo</MenuItem>
              <MenuItem value="AP">Ascoli Piceno</MenuItem>
              <MenuItem value="AT">Asti</MenuItem>
              <MenuItem value="AV">Avellino</MenuItem>
              <MenuItem value="BA">Bari</MenuItem>
              <MenuItem value="BT">Barletta-Andria-Trani</MenuItem>
              <MenuItem value="BL">Belluno</MenuItem>
              <MenuItem value="BN">Benevento</MenuItem>
              <MenuItem value="BG">Bergamo</MenuItem>
              <MenuItem value="BI">Biella</MenuItem>
              <MenuItem value="BO">Bologna</MenuItem>
              <MenuItem value="BZ">Bolzano</MenuItem>
              <MenuItem value="BS">Brescia</MenuItem>
              <MenuItem value="BR">Brindisi</MenuItem>
              <MenuItem value="CA">Cagliari</MenuItem>
              <MenuItem value="CL">Caltanissetta</MenuItem>
              <MenuItem value="CB">Campobasso</MenuItem>
              <MenuItem value="CE">Caserta</MenuItem>
              <MenuItem value="CT">Catania</MenuItem>
              <MenuItem value="CZ">Catanzaro</MenuItem>
              <MenuItem value="CH">Chieti</MenuItem>
              <MenuItem value="CO">Como</MenuItem>
              <MenuItem value="CS">Cosenza</MenuItem>
              <MenuItem value="CR">Cremona</MenuItem>
              <MenuItem value="KR">Crotone</MenuItem>
              <MenuItem value="CN">Cuneo</MenuItem>
              <MenuItem value="EN">Enna</MenuItem>
              <MenuItem value="FM">Fermo</MenuItem>
              <MenuItem value="FE">Ferrara</MenuItem>
              <MenuItem value="FI">Firenze</MenuItem>
              <MenuItem value="FG">Foggia</MenuItem>
              <MenuItem value="FC">Forl-Cesena</MenuItem>
              <MenuItem value="FR">Frosinone</MenuItem>
              <MenuItem value="GE">Genova</MenuItem>
              <MenuItem value="GO">Gorizia</MenuItem>
              <MenuItem value="GR">Grosseto</MenuItem>
              <MenuItem value="IM">Imperia</MenuItem>
              <MenuItem value="IS">Isernia</MenuItem>
              <MenuItem value="AQ">L'aquila</MenuItem>
              <MenuItem value="SP">La spezia</MenuItem>
              <MenuItem value="LT">Latina</MenuItem>
              <MenuItem value="LE">Lecce</MenuItem>
              <MenuItem value="LC">Lecco</MenuItem>
              <MenuItem value="LI">Livorno</MenuItem>
              <MenuItem value="LO">Lodi</MenuItem>
              <MenuItem value="LU">Lucca</MenuItem>
              <MenuItem value="MC">Macerata</MenuItem>
              <MenuItem value="MN">Mantova</MenuItem>
              <MenuItem value="MS">Massa-Carrara</MenuItem>
              <MenuItem value="MT">Matera</MenuItem>
              <MenuItem value="ME">Messina</MenuItem>
              <MenuItem value="MI">Milano</MenuItem>
              <MenuItem value="MO">Modena</MenuItem>
              <MenuItem value="MB">Monza e Brianza</MenuItem>
              <MenuItem value="NA">Napoli</MenuItem>
              <MenuItem value="NO">Novara</MenuItem>
              <MenuItem value="NU">Nuoro</MenuItem>
              <MenuItem value="OR">Oristano</MenuItem>
              <MenuItem value="PD">Padova</MenuItem>
              <MenuItem value="PA">Palermo</MenuItem>
              <MenuItem value="PR">Parma</MenuItem>
              <MenuItem value="PV">Pavia</MenuItem>
              <MenuItem value="PG">Perugia</MenuItem>
              <MenuItem value="PU">Pesaro e Urbino</MenuItem>
              <MenuItem value="PE">Pescara</MenuItem>
              <MenuItem value="PC">Piacenza</MenuItem>
              <MenuItem value="PI">Pisa</MenuItem>
              <MenuItem value="PT">Pistoia</MenuItem>
              <MenuItem value="PN">Pordenone</MenuItem>
              <MenuItem value="PZ">Potenza</MenuItem>
              <MenuItem value="PO">Prato</MenuItem>
              <MenuItem value="RG">Ragusa</MenuItem>
              <MenuItem value="RA">Ravenna</MenuItem>
              <MenuItem value="RC">Reggio Calabria</MenuItem>
              <MenuItem value="RE">Reggio Emilia</MenuItem>
              <MenuItem value="RI">Rieti</MenuItem>
              <MenuItem value="RN">Rimini</MenuItem>
              <MenuItem value="RM">Roma</MenuItem>
              <MenuItem value="RO">Rovigo</MenuItem>
              <MenuItem value="SA">Salerno</MenuItem>
              <MenuItem value="SS">Sassari</MenuItem>
              <MenuItem value="SV">Savona</MenuItem>
              <MenuItem value="SI">Siena</MenuItem>
              <MenuItem value="SR">Siracusa</MenuItem>
              <MenuItem value="SO">Sondrio</MenuItem>
              <MenuItem value="SU">Sud Sardegna</MenuItem>
              <MenuItem value="TA">Taranto</MenuItem>
              <MenuItem value="TE">Teramo</MenuItem>
              <MenuItem value="TR">Terni</MenuItem>
              <MenuItem value="TO">Torino</MenuItem>
              <MenuItem value="TP">Trapani</MenuItem>
              <MenuItem value="TN">Trento</MenuItem>
              <MenuItem value="TV">Treviso</MenuItem>
              <MenuItem value="TS">Trieste</MenuItem>
              <MenuItem value="UD">Udine</MenuItem>
              <MenuItem value="VA">Varese</MenuItem>
              <MenuItem value="VE">Venezia</MenuItem>
              <MenuItem value="VB">Verbano-Cusio-Ossola</MenuItem>
              <MenuItem value="VC">Vercelli</MenuItem>
              <MenuItem value="VR">Verona</MenuItem>
              <MenuItem value="VV">Vibo valentia</MenuItem>
              <MenuItem value="VI">Vicenza</MenuItem>
              <MenuItem value="VT">Viterbo</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="email"
            label="Email"
            variant="filled"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          <TextField
            id="nota"
            label="Nota o Commento"
            variant="filled"
            multiline
            rows={4}
            value={formData.nota}
            onChange={(e) => setFormData({ ...formData, nota: e.target.value })}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={formData.privacy}
                onChange={(e) =>
                  setFormData({ ...formData, privacy: e.target.checked })
                }
              />
            }
            label="Accetto la politica sulla privacy"
          />
          {alertText !== "" && <Alert severity="warning">{alertText}</Alert>}
          <Button
            variant="contained"
            style={{
              fontWeight: '900',
              backgroundColor:'#3cd115',
            }}
            onClick={handleClickInvia}
          >
            Invia
          </Button>
          <Fade  
                in={loading}
                style={{
                  transitionDelay: loading ? "100ms" : "0ms",
                }}
                unmountOnExit
              >
                <LinearProgress color="secondary" />
              </Fade>
          <Button
            variant="contained"
            color="secondary"
            style={{
                fontWeight: '900',
              }}
            onClick={handleClearForm}
          >
            Nuovo
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default Registro;
